import React from "react"
import styled from "styled-components"
import { POLICY_STYLES } from "../../../AppConst"
import { SectionTitle } from '../../common/SectionTitle'

const Wrapper = styled.div`
  ${POLICY_STYLES}
`

export const SitePolicy: React.VFC = () => {
  return (
    <Wrapper>
      <SectionTitle title='site policy' subTitle='サイトポリシー' />
      <p>
        当ウェブサイトは、下記の方針に基づいて運営いたします。<br />
        当社では、当ウェブサイトのすべての利用者は、下記の方針を理解し、これに同意されたものとみなします。ご利用の前に、あらかじめご一読ください。
      </p>
      <section>
        <h3>免責事項</h3>
        <p>
          当社が管理・運営するすべてのWebサイト・Webサービス（以下「本サービス」といいます。）の利用について、当社は、以下の事項につき一切の責任を負わないものとします。
        </p>
        <ul>
          <li>本サービスに関する一切の事項についての完全性、正確性、有用性、安全性、合法性、道徳性、信頼性等</li>
          <li>本サービスの内容の変更又は本サービスの中断・停止・終了によって生じた損害</li>
          <li>本サービスのアクセス過多又はその他予期せぬ要因による通信障害やシステム障害等によって生じた損害</li>
          <li>本サービスに掲載された当社以外が運営するサービスの利用又は当社以外が販売する商品を購入して生じた損害</li>
          <li>本サービスに関連して利用者間で生じたトラブル</li>
          <li>本サービスの一切の事項について生じた損害</li>
        </ul>
      </section>
      <section>
        <h3>画像の出典元</h3>
        <p>
          本サービスにて使用している一部の画像は、以下のフリー素材配布サービスから使用しています。また、本サービスに掲載されている画像を二次利用する場合、提供元の了承を得る必要があります。
        </p>
        <ul>
          <li>
            <a href="https://www.ac-illust.com/" target="_blank" rel="noopener noreferrer">イラストＡＣ</a>
          </li>
          <li>
            <a href="https://www.irasutoya.com/" target="_blank" rel="noopener noreferrer">いらすとや</a>
          </li>
          <li>
            <a href="https://www.photo-ac.com/" target="_blank" rel="noopener noreferrer">写真ＡＣ</a>
          </li>
          <li>
            <a href="https://www.pakutaso.com/" target="_blank" rel="noopener noreferrer">ぱくたそ</a>
          </li>
          <li>
            <a href="https://www.xn--eckzb3bzhw32znfcp1zduw.com/" target="_blank" rel="noopener noreferrer">無料イラスト素材 ドットコム</a>
          </li>
          <li>
            <a href="https://www.canva.com/ja_jp/" target="_blank" rel="noopener noreferrer">Canva</a>
          </li>
          <li>
            <a href="https://icon-rainbow.com/" target="_blank" rel="noopener noreferrer">Icon rainbow</a>
          </li>
          <li>
            <a href="https://pixabay.com/ja/" target="_blank" rel="noopener noreferrer">Pixabay</a>
          </li>
          <li>
            <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">Unsplash</a>
          </li>
        </ul>
      </section>
      <section>
        <h3>Amazonアソシエイトについて</h3>
        <p>Amazonのアソシエイトとして、ネクストライブ株式会社は適格販売により収入を得ています。</p>
      </section>
    </Wrapper>
  )
}