import React from "react"
import { Policy } from '../components/policy'

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `SITE POLICY`, path: `policy` },
]

export default function PrivacyPolicyPage() {
  return (
    <Policy breadcrumbsData={breadcrumbsData}/>
  )
}
