import React from "react"
import Layout from "../../template/Layout"
import styled from "styled-components"
import Head from "../../head";
import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES } from "../../AppConst"
import { SpWrapper } from "../common/SpWrapper"
import { PrivacyPolicy } from "./privacy"
import { SitePolicy } from "./site"
import {
  Props as BreadcrumbsData,
} from "../common/Breadcrumbs"

/**
 * Style
 */
const Container = styled.div`
  ${WRAPPER_STYLES}
  ${TABLET_STYLES}
  box-sizing: border-box;
  max-width: 960px;
`
const PoliciesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  width: 100%;
  > div:nth-child(1) {
    border-bottom: solid 1px #222222;
  }
  > div:nth-child(2) {
    > div {
      margin-top: -32px;
    }
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-bottom: 32px;
  }
`

/**
 * Component
 */

type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
}

export const Policy: React.FC<Props> = ({
  breadcrumbsData,
}) => {
  return (
    <Layout sidebar={false} breadcrumbsData={breadcrumbsData}>
      <Head
        title="サイトポリシー | ネクストライブ株式会社"
        description="ネクストライブ株式会社のサイトポリシーページです。
                    当ウェブサイトの運営における方針や個人情報の取扱いについて掲載しています。"
      />
      <Container>
        <SpWrapper>
          <PoliciesWrapper>
            <SitePolicy />
            <PrivacyPolicy />
          </PoliciesWrapper>
        </SpWrapper>
      </Container>
    </Layout>
  )
}
