import React from "react"
import { Link } from "gatsby";
import styled from "styled-components"
import { POLICY_STYLES } from "../../../AppConst";
import { SectionTitle } from '../../common/SectionTitle'

const Wrapper = styled.div`
  ${POLICY_STYLES}
`

export const PrivacyPolicy: React.VFC = () => {
  return (
    <Wrapper>
      <SectionTitle title='privacy policy' subTitle='個人情報保護方針' />
      <p>
        ネクストライブ株式会社（以下「当社」といいます。）はソフトウェア開発業務、Webサービス制作・運営業務、人材育成業務を遂行しております。<br />
        事業活動を通じてお客様から取得する個人情報及び当社従業者の個人情報(以下、「個人情報」といいます。)を様々な形で取扱っております。<br />
        個人情報は当社にとって大変重要な情報資産であり、その個人情報を確実に保護することは、当社の重要な社会的責務と認識しております。<br />
        よって当社は、個人情報保護に関する法令、国が定める指針その他の規範を遵守し、個人情報を正確かつ誠実に取り扱うため、<br />
        以下に掲げた事項を常に念頭に置き、お客様の個人情報保護に万全を尽くしてまいります。<br />
        <br />
        当社は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、<br />
        全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
      </p>
      <section>
        <h3>個人情報の取得及び利用</h3>
        <p>
          個人情報保護の重要性を従業者一同が認識し、当社の事業目的を遂行する範囲内で、適法かつ公正な手段によって、これを取得、利用します。
          また、個人情報の目的外利用については一切これを行いません。目的外利用の必要が生じた場合は、新たな利用目的について、あらかじめご本人の同意を得た上で利用します。<br />
          なお、個人情報の保護に関する法律（以下、「法」といいます。）第18条における当社の個人情報を取り扱う事業、取得方法、利用目的については以下の通りです。
        </p>
        <h4>個人情報を取り扱う事業内容</h4>
        <ul>
          <li>ソフトウェア開発</li>
          <li>Webサイト制作</li>
          <li>システム開発支援</li>
          <li>プログラマ育成</li>
          <li>Webサービスの運営</li>
        </ul>
        <h4>取得方法</h4>
        <ul>
          <li>書類</li>
          <li>電子データ</li>
          <li>名刺</li>
          <li>顧客からのデータ入力およびアップロード</li>
          <li>ブラウザのクッキー</li>
          <li>セミナー、イベント開催時における写真撮影</li>
          <li>電話によるお問い合わせ</li>
        </ul>
        <h4>利用目的</h4>
        <ul>
          <li>ソフトウェア開発業務を遂行するため</li>
          <li>Webサイト制作のため</li>
          <li>運営するWebサイト・Webサービスの登録の受付、本人確認、サービスの提供、維持、保護及び改善のため</li>
          <li>運営するWebサイト・Webサービスに関するご案内、お問合せ等への対応のため</li>
          <li>営業業務における取引に掛かる業務遂行のため</li>
          <li>従業者の労務管理・福利厚生のため</li>
          <li>採用応募者への連絡、採用選考のため</li>
          <li>業務エリアへの入退室管理</li>
          <li>頂いたご意見、ご要望、お問い合わせ内容に対して回答をするため</li>
        </ul>
      </section>
      <section>
        <h3>法第22条および23条における個人情報の第三者への提供・委託方針</h3>
        <p>
          当社は、当社が保有する個人情報について、個人情報保護法等の法令に定めのある場合を除き、
          ご本人の同意を得ることなく第三者に提供・委託することはいたしません。<br />
          ご本人の同意を得て個人情報の取り扱いの全部または一部を第三者に委託する場合は、当該第三者について厳正な調査を行い、
          取り扱いを委託された個人情報の安全管理が図られるよう当該第三者に対する必要かつ適切な監督を行います。<br />
          また、個人を特定しないクライアント環境情報、属性情報および行動履歴の取得・保存について、
          第三者へ提供する場合、あくまで統計的な情報の提供に留め、個人情報の提供は行いません。<br />
          ただし、次に掲げる場合は上記に定める第三者には該当しないものとします。
        </p>
        <ul>
          <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
          <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
        </ul>
      </section>
      <section>
        <h3>個人情報の取得及び利個人情報に関する法令の遵守方針</h3>
        <p>
          当社は、個人情報に関する法令、国が定める指針その他の規範(以下、「法令等」といいます。)
          及び個人情報保護マネジメントシステムを遵守し、個人情報を誠実に取り扱います。
          また、法令等を常に把握することに努め、当社事業に従事する従業者(以下、「従業者」といいます。)、
          取引先に周知し順守いたします。
        </p>
      </section>
      <section>
        <h3>法第20条における個人情報に対するリスク管理方針</h3>
        <p>
          当社は、個人情報の漏えい、滅失又はき損等の危険に対し、技術面及び組織面において必要かつ適切な安全対策、防止措置を講じます。<br />
          また、定期的な点検を実施し、発見された違反、事件及び事故に対して、速やかにこれを是正するとともに、弱点に対する予防措置を実施します。<br />
          従業者、取引先には安全に関する教育を徹底いたします。
        </p>
      </section>
      <section>
        <h3>個人情報の開示</h3>
        <p>
          当社は、ご本人からの個人情報についての開示の請求があった場合には、次の各号の場合を除き、速やかに開示をいたします。<br />
          その際、ご本人であることを確認できない場合には、開示に応じません。
        </p>
        <ul>
          <li>当該個人情報の在否が明らかになることによって、本人又は第三者の生命、身体又は財産に危害が及ぶおそれのあるもの</li>
          <li>当該個人情報の在否が明らかになることによって、違法又は不当な行為を助長し、又は誘発するおそれのあるもの</li>
          <li>当該個人情報の在否が明らかになることによって、国の安全が害されるおそれ、他国若しくは国際機関との信頼関係が損なわれるおそれ又は他国若しくは国際機関との交渉上不利益を被るおそれのあるもの</li>
          <li>当該個人情報の在否が明らかになることによって、犯罪の予防、鎮圧又は捜査その他の公共の安全と秩序維持に支障が及ぶおそれのあるもの</li>
        </ul>
      </section>
      <section>
        <h3>保有個人データの開示等</h3>
        <p>
          当社が保有する個人データの開示・訂正・追加・削除の請求がある方は、<Link to={"/personal"}>こちら</Link>をご参照ください。
        </p>
      </section>
      <section>
        <h3>個人情報の管理</h3>
        <p>
          当社は、お客様の個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、
          セキュリティシステムの維持・管理体制の整備・社員（委託先を含みます。）に対する教育の徹底等の必要な措置を講じ、
          安全対策を実施し個人情報の厳重な管理を行ないます。
        </p>
      </section>
      <section>
        <h3>苦情及び相談に対する対応方針</h3>
        <p>
          当社の個人情報の取扱い及び個人情報保護マネジメントシステムに関する苦情及び相談については、
          受け付け次第、適切、かつ、迅速な対応をいたします。<br />
          また、その体制、手順については確立・整備をおこない、常に適切に対応出来る体制を維持していきます。
        </p>
      </section>
      <section>
        <h3>法令、規範の遵守と見直し</h3>
        <p>
          当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、
          本ポリシーの内容を適宜見直し、個人情報保護に関する取組みの継続的改善に努めます。
        </p>
      </section>
      <section>
        <h3>個人情報の取り扱いに関するお問い合わせ先</h3>
        <p>
          〒553-0003<br />
          大阪市福島区福島1-4-4 セントラル70 3F<br />
          ネクストライブ株式会社<br />
          電話番号 06-6147-2675<br />
          受付時間 月曜日〜金曜日（祝日、年末年始は除く）<br />
          9時30分〜12時、13時〜16時30分
        </p>
      </section>
    </Wrapper>
  )
}